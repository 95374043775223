var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      { attrs: { "vue-app-id": "footer", id: "footer" } },
      "div",
      _vm.$props,
      false
    ),
    [
      _vm.full === "Y" ? _c("MainBlock") : _vm._e(),
      _vm._v(" "),
      _c("ScrollTop"),
      _vm._v(" "),
      _c("CookiesBlock"),
      _vm._v(" "),
      _c("AdditionalBlock", _vm._b({}, "AdditionalBlock", _vm.$props, false))
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }