<script>
export default {
  props: {
    full: {},
    copyright_year: {},
    copyright_name: {},
    copyright_address: {},
    copyright_ogrn: {},
    phone_eshop: {},
    phone_office: {},
    phone_office_2: {},
    // @see https://jira.itech-group.ru/browse/OCHKARBUS-16939 убраны дополнительные телефоны
    show_additional_phones: {},
  },
};
</script>

<template>
  <div class="footer-additional">
    <div class="footer-additional__container">
      <div class="footer-additional__column1">
        <div class="footer-additional__copyright">
          <p>© {{ copyright_year }} {{ copyright_name }}</p>
          <p>{{ copyright_address }} ОГРН {{ copyright_ogrn }}</p>
        </div>
      </div>
      <div class="footer-additional__column2">
        <div class="footer-additional__phone" v-if="full === 'Y'">
          <div class="footer-additional__number">
            <a class="header__nav-link header__nav-link--phone" :href="`tel:${phone_eshop}`">{{ phone_eshop }}</a>
          </div>
          <div class="footer-additional__content">
            <p>Круглосуточно</p>
          </div>
        </div>
      </div>
      <div class="footer-additional__column3">
        <div class="footer-additional__phone" v-if="show_additional_phones && full === 'Y'">
          <div class="footer-additional__number">
            <a :href="`tel:${phone_office_2}`">{{ phone_office_2 }}</a
            ><br />
            <a :href="`tel:${phone_office}`" style="line-height: 1.5;" v-if="phone_office">{{ phone_office }}</a>
          </div>
          <div class="footer-additional__content">
            <p>Салоны оптики</p>
            <p>пн - пт с 9:00 до 18:00</p>
          </div>
        </div>
      </div>
      <div class="footer-additional__column4"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
