var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-additional" }, [
    _c("div", { staticClass: "footer-additional__container" }, [
      _c("div", { staticClass: "footer-additional__column1" }, [
        _c("div", { staticClass: "footer-additional__copyright" }, [
          _c("p", [
            _vm._v(
              "© " +
                _vm._s(_vm.copyright_year) +
                " " +
                _vm._s(_vm.copyright_name)
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.copyright_address) +
                " ОГРН " +
                _vm._s(_vm.copyright_ogrn)
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-additional__column2" }, [
        _vm.full === "Y"
          ? _c("div", { staticClass: "footer-additional__phone" }, [
              _c("div", { staticClass: "footer-additional__number" }, [
                _c(
                  "a",
                  {
                    staticClass: "header__nav-link header__nav-link--phone",
                    attrs: { href: "tel:" + _vm.phone_eshop }
                  },
                  [_vm._v(_vm._s(_vm.phone_eshop))]
                )
              ]),
              _vm._v(" "),
              _vm._m(0)
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-additional__column3" }, [
        _vm.show_additional_phones && _vm.full === "Y"
          ? _c("div", { staticClass: "footer-additional__phone" }, [
              _c("div", { staticClass: "footer-additional__number" }, [
                _c("a", { attrs: { href: "tel:" + _vm.phone_office_2 } }, [
                  _vm._v(_vm._s(_vm.phone_office_2))
                ]),
                _c("br"),
                _vm._v(" "),
                _vm.phone_office
                  ? _c(
                      "a",
                      {
                        staticStyle: { "line-height": "1.5" },
                        attrs: { href: "tel:" + _vm.phone_office }
                      },
                      [_vm._v(_vm._s(_vm.phone_office))]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm._m(1)
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-additional__column4" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-additional__content" }, [
      _c("p", [_vm._v("Круглосуточно")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-additional__content" }, [
      _c("p", [_vm._v("Салоны оптики")]),
      _vm._v(" "),
      _c("p", [_vm._v("пн - пт с 9:00 до 18:00")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }