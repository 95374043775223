<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      catalogMenu: state => state?.header?.catalogMenu,
    }),
  },
};
</script>

<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__group">
        <a
          v-for="(item, i) of catalogMenu"
          :key="`category-${i}`"
          class="footer__item footer__item-section"
          :href="item.link"
        >
          {{ item.name }}
        </a>
      </div>

      <div class="footer__group">
        <a class="footer__item footer__item-section" href="/e-shop/">ИНТЕРНЕТ-МАГАЗИН</a>
        <a class="footer__item" href="/profile/">Личный кабинет</a>
        <a class="footer__item" href="/punkty-vidachy/">Пункты выдачи заказов</a>
        <a class="footer__item" href="/oplata/">Оплата</a>
        <a class="footer__item" href="/e-shop-delivery/">Доставка</a>
        <a class="footer__item" href="/obmen-i-vozvrat/">Обмен и возврат</a>
        <a class="footer__item" href="/oformlenie-zakaza/">Оформление заказа</a>
        <a class="footer__item" href="/personalnye-dannye/"> Персональные данные</a>
        <a class="footer__item" href="/public-oferta/">Публичный договор-оферта</a>
        <a class="footer__item" href="/sertifikat/">Регистрационные удостоверения и сертификаты на продукцию</a>
        <a class="footer__item" href="/faq/">FAQ</a>
      </div>

      <div class="footer__group">
        <a class="footer__item footer__item-section" href="/salony-optiki/">САЛОНЫ ОПТИКИ</a>
        <a class="footer__item" href="/news-rejim/">Изменения режима работы салонов</a>
        <a class="footer__item" href="/promotion-events/">Акции</a>
        <a class="footer__item" href="/special-offers/">Наши преимущества</a>
        <a class="footer__item" href="/experts/">Наши специалисты</a>
        <a class="footer__item" href="/medical-services/">Медицинские услуги</a>
        <a class="footer__item" href="/public-offer/">Публичный договор</a>
        <a class="footer__item" href="/tovari-uslugi/">Товары и услуги</a>
        <a class="footer__item" href="/consumer-corner/">Уголок потребителя </a>
        <a class="footer__item" href="/page-order-status/">Статус заказа</a>
        <a class="footer__item" href="/customers-service/">Сервис</a>
        <a class="footer__item" href="/arendodateliam/">Арендодателям</a>
      </div>

      <div class="footer__group">
        <a class="footer__item footer__item-section" href="/about/">О КОМПАНИИ</a>
        <a class="footer__item" href="/news/">Новости</a>
        <a class="footer__item" href="/brend-ochkarik-new">Бренд «Очкарик»</a>
        <a class="footer__item" href="/history/">История сети</a>
        <a class="footer__item" href="/our-team/">Обучение и развитие персонала</a>
        <a class="footer__item" href="/nauchno-proizvodstvennaya-baza-new">Научно-производственная база</a>
        <a class="footer__item" href="/best-worlds-dealers/">Наши поставщики</a>
        <a class="footer__item" href="/caryera/">Карьера</a>
        <a class="footer__item" href="/vacancies/">Вакансии</a>
        <a class="footer__item" href="/contact/">Контакты</a>
      </div>

      <div class="footer__group">
        <div class="footer__item footer__item-external-resources__markets">
          <div class="footer__item-section footer__item-section--mb-20">ДЛЯ МОБИЛЬНЫХ УСТРОЙСТВ</div>
          <a class="footer__mobile" href="https://itunes.apple.com/ru/app/ockarik/id871836087" target="_blank">
            <img
              class="footer__mobile__img"
              :src="'/skins/default/en/images/apple-store-link.svg' | cdn"
              alt="app-store-link"
            />
          </a>

          <a class="footer__mobile" href="https://play.google.com/store/apps/details?id=com.ochkarikrn" target="_blank">
            <img
              class="footer__mobile__img"
              :src="'/skins/default/en/images/google-play-link.svg' | cdn"
              alt="google-play-link"
            />
          </a>
        </div>

        <div class="footer__item footer__item-external-resources">
          <div class="footer__item-section footer__item-section--mb-20">МЫ В СОЦИАЛЬНЫХ СЕТЯХ</div>
          <a
            class="footer__network analyticsSendGoogleEventPerehodVK"
            href="http://vk.com/club19204041"
            target="_blank"
          >
            <img class="footer__network__img" :src="'/skins/default/en/images/vk.svg' | cdn" alt="vk-link" />
            <span class="footer__network__link">Вконтакте</span>
          </a>
          <a class="footer__network" href="https://telegram.me/ochkarik_opticbot" target="_blank">
            <img class="footer__network__img" :src="'/skins/default/en/images/tgk.svg' | cdn" alt="tgk-link" />
            <span class="footer__network__link">Телеграм</span>
          </a>
          <!-- <a
            class="footer__network analyticsSendGoogleEventPerehodIM"
            href="https://instagram.com/ochkarik_optica"
            target="_blank"
          >
            <img
              class="footer__network__img"
              :src="'/skins/default/en/images/instagram.png' | cdn"
              alt="instagram-link"
            />
            <span class="footer__network__link">Instagram</span>
          </a> -->
          <a class="footer__network" href="https://zen.yandex.ru/id/61b9b45479d8b44cbf72c628" target="_blank">
            <!-- обертка для того, чтобы стили adblock не скрывали иконку Яндекс.Дзена  -->
            <span>
              <img class="footer__network__img" :src="'/skins/default/en/images/zen.svg' | cdn" alt="zen-link" />
            </span>
            <span class="footer__network__link">Яндекс.Дзен</span>
          </a>
          <!-- <a
            class="footer__network analyticsSendGoogleEventPerehodFb"
            href="http://www.facebook.com/pages/%D0%9E%D1%87%D0%BA%D0%B0%D1%80%D0%B8%D0%BA/148161638679393"
            target="_blank"
          >
            <img class="footer__network__img" :src="'/skins/default/en/images/fb.png' | cdn" alt="fb-link" />
            <span class="footer__network__link">Facebook</span>
          </a> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped src="./style.scss"></style>
