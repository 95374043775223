<script>
import AdditionalBlock from './components/AdditionalBlock';
import CookiesBlock from './components/CookiesBlock';
import MainBlock from './components/MainBlock';
import ScrollTop from './components/ScrollTop';

export default {
  props: {
    full: {},
    copyright_year: {},
    copyright_name: {},
    copyright_address: {},
    copyright_ogrn: {},
    phone_eshop: {},
    phone_office: {},
    phone_office_2: {},
    show_additional_phones: {},
  },
  components: {
    AdditionalBlock,
    CookiesBlock,
    MainBlock,
    ScrollTop,
  },
};
</script>

<template>
  <div vue-app-id="footer" v-bind="$props" id="footer">
    <MainBlock v-if="full === 'Y'" />
    <ScrollTop />
    <CookiesBlock />
    <AdditionalBlock v-bind="$props" />
  </div>
</template>
<style lang="scss">
#footer {
  clear: both;
}

@media (max-width: 1279px) {
  body {
    #supportTrigger,
    .online-chat-root .device-type-mobile .popup-notification {
      bottom: 120px !important;
      right: 15px !important;
      z-index: 9999 !important;
    }
    .online-chat-root.online-chat-root-MeTalk .online-chat-components-wrapper #supportTrigger.trigger {
      bottom: 120px !important;
      right: 15px !important;
    }
    .online-chat-widget-draggable-wrapper {
      top: unset !important;
      bottom: 120px !important;
    }
  }
}
</style>
