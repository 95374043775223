<script>
export default {
  data() {
    return {
      display: 'none',
    };
  },
  mounted() {
    if (!localStorage.getItem('cookies')) {
      this.display = 'flex';
    }
  },
  methods: {
    close() {
      this.display = 'none';
      localStorage.setItem('cookies', 'yes');
    },
  },
};
</script>

<template>
  <div class="cookies-wrapper" :style="{ display }">
    <span class="cookies-wrapper-close" @click="close()"></span>
    <p class="cookies-text">
      Мы используем cookies в статистических и рекламных целях, а также для адаптации сайта к потребностям
      пользователей. Если вы не согласны с этим, вы можете покинуть сайт или изменить настройки cookies в браузере.
    </p>
    <button class="cookies-button" @click="close()">Принять</button>
  </div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
