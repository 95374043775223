var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cookies-wrapper", style: { display: _vm.display } },
    [
      _c("span", {
        staticClass: "cookies-wrapper-close",
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "cookies-text" }, [
        _vm._v(
          "\n    Мы используем cookies в статистических и рекламных целях, а также для адаптации сайта к потребностям\n    пользователей. Если вы не согласны с этим, вы можете покинуть сайт или изменить настройки cookies в браузере.\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "cookies-button",
          on: {
            click: function($event) {
              return _vm.close()
            }
          }
        },
        [_vm._v("Принять")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }