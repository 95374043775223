var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass:
      "page-footer__scroll_button main-sprite__before layout-icon__page-footer",
    class: { "page-footer__scroll_button_visible": _vm.visible },
    on: {
      click: function($event) {
        return _vm.click()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }