<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    if (!window.mobileVersion && document.location.pathname !== '/') {
      document.addEventListener('scroll', () => {
        this.visible = window.scrollY > 100;
      });
    }
  },
  methods: {
    click() {
      this.scrollToTop(400);
    },
    // thanks https://stackoverflow.com/a/24559613
    scrollToTop(duration) {
      // cancel if already on top
      if (document.scrollingElement.scrollTop === 0) return;

      const totalScrollDistance = document.scrollingElement.scrollTop;
      let scrollY = totalScrollDistance;
      let oldTimestamp = null;

      function step(newTimestamp) {
        if (oldTimestamp !== null) {
          // if duration is 0 scrollY will be -Infinity
          scrollY -= (totalScrollDistance * (newTimestamp - oldTimestamp)) / duration;
          // eslint-disable-next-line no-return-assign
          if (scrollY <= 0) return (document.scrollingElement.scrollTop = 0);
          document.scrollingElement.scrollTop = scrollY;
        }
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      }
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<template>
  <div
    class="page-footer__scroll_button main-sprite__before layout-icon__page-footer"
    :class="{ 'page-footer__scroll_button_visible': visible }"
    @click="click()"
  ></div>
</template>

<style lang="scss" scoped src="./style.scss"></style>
